import React, { useState, useEffect } from "react";
import "./UpperNav.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const DropdownMenu = ({ title, items, isOpen, toggle, className }) => {
  return (
    <div
      className={`dropdown ${className}`}
      onMouseEnter={() => toggle(true)}
      onMouseLeave={() => toggle(false)}
    >
      <button className="channels-box" onClick={toggle}>
        <span>{title}</span>
        <span className="arrow-down"></span>
      </button>
      {isOpen && (
        <ul className="menu-dropAdmin">
          {items.map((item, index) => (
            <li key={index}>
              <a className="dropdown-item" href={item.url}>
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const UserProfile = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const colors = [
      "red",
      "green",
      "blue",
      "pink",
      "lightblue",
      "lightgreen",
      "aqua",
      "navy",
      "lime",
      "yellow",
      "fuchsia",
      "maroon",
      "darksalmon",
    ];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    document.querySelector("#header_profile_svg circle").style.fill =
      randomColor;
    document.querySelector("#header_profile_svg text").style.fill = "white";
  }, []);

  const handleLogout = () => {
    try {
      
      Cookies.remove("token");
      Cookies.remove("adminName");
      Cookies.remove("adminuser_type");
      Cookies.remove("adminID");

      navigate("/admin");
      window.location.reload();
    } catch (error) {
      console.log("Couldn't log out");
    }
  };

  return (
    <div
      className="user_pro_file"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="user_pro_file_two">
        <svg width="30" height="30" id="header_profile_svg">
          <circle
            cx="15"
            cy="15"
            r="15"
            stroke="green"
            strokeWidth="0"
            fill="pink"
          />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            stroke="blue"
            strokeWidth="0"
            dy=".3em"
            fontSize="16"
          >
            A
          </text>
        </svg>
        <span className="user_name">Admin</span>
      </div>
      {isOpen && (
        <ul className="drop-login-new">
          <li className="affiliate_li">
            <a
              href="https://www.needtoday.com/account/affiliate"
              className="drop-seting-new"
            >
              <i className="fa fa-handshake"></i> Become a Partner
            </a>
          </li>
          <li className="settings">
            <a
              href="https://needtoday.com/account/settings"
              className="drop-seting-new"
            >
              <i className="fa fa-tachometer-alt"></i> Settings
            </a>
          </li>
          <li className="logout">
            <button
            //   href="#"
              className="drop-logout-new"
              onClick={() => handleLogout()}
              style={{ border: "none", width: "100%", textAlign: "left" }}
            >
              <i className="fas fa-sign-out-alt"></i> Logout
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const [channelOpen, setChannelOpen] = useState(false);
  const [dashboardOpen, setDashboardOpen] = useState(false);
  const [sellerDashboardOpen, setSellerDashboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const channels = [
    { name: "Classifieds", url: "https://classifieds.needtoday.com/" },
    { name: "Deals", url: "https://deals.needtoday.com/" },
    { name: "Digital Marketing", url: "http://www.digital.needtoday.com" },
    { name: "Education", url: "https://www.education.needtoday.com" },
    { name: "Entertainment", url: "https://www.entertainment.needtoday.com" },
    { name: "Freelancer", url: "https://www.freelancer.needtoday.com" },
    { name: "Institute", url: "https://www.institute.needtoday.com/" },
    { name: "Jobs", url: "https://www.jobs.needtoday.com" },
    { name: "Marketplace", url: "http://www.marketplace.needtoday.com" },
    { name: "Matrimony", url: "https://matrimony.needtoday.com/" },
    { name: "News", url: "https://news.needtoday.com/" },
    { name: "Realty", url: "https://www.realty.needtoday.com/" },
    { name: "Services", url: "http://services.needtoday.com" },
    { name: "Travel", url: "https://www.travelb2b.needtoday.com" },
    { name: "Tutoring", url: "http://tutoring.needtoday.com/" },
  ];

  const myDashboardItems = [
    { name: "Classifieds", url: "https://classifieds.needtoday.com/" },
    { name: "Deals", url: "https://deals.needtoday.com/" },
    { name: "Education", url: "https://www.education.needtoday.com" },
    { name: "Entertainment", url: "https://www.entertainment.needtoday.com" },
    { name: "Freelancer", url: "https://www.freelancer.needtoday.com" },
    { name: "Jobs", url: "https://jobs.needtoday.com/" },
    { name: "Marketplace", url: "http://www.marketplace.needtoday.com" },
    { name: "Matrimony", url: "https://matrimony.needtoday.com/" },
    { name: "Realty", url: "https://realty.needtoday.com/" },
    { name: "Services", url: "http://services.needtoday.com" },
    { name: "Tutoring", url: "http://tutoring.needtoday.com/" },
    // Add remaining items...
  ];

  const sellerDashboardItems = [
    { name: "Classifieds", url: "https://classifieds.needtoday.com/" },
    { name: "Deals (Merchant)", url: "https://deals.needtoday.com/" },
    { name: "Education", url: "https://www.education.needtoday.com" },
    {
      name: "Freelancer (Seller)",
      url: "https://www.freelancer.needtoday.com/",
    },
    { name: "Jobs (Employer)", url: "https://jobs.needtoday.com/" },
    {
      name: "Marketplace (Seller) - B2C",
      url: "http://www.marketplace.needtoday.com/",
    },
    { name: "Matrimony", url: "https://matrimony.needtoday.com/" },
    { name: "Realty (Developer)", url: "https://realty.needtoday.com/" },
    { name: "Services (Tasker)", url: "http://services.needtoday.com" },
    { name: "Travel Agent", url: "https://travelb2b.needtoday.com/" },
    { name: "Tutoring (Teacher)", url: "http://tutoring.needtoday.com/" },
    // Add remaining items...
  ];

  return (
    <div className="menus_container">
      {/* First Top Header */}
      <div className="first_top_header-bg">
        <div
          className={`container-fluid ${
            isMobile ? "container-fluid-mobile" : ""
          }`}
        >
          <div className="first_top_header row align-items-center">
            <div className="col-auto">
              <a href="https://www.needtoday.com" className="need_logo">
                <img
                  src="https://www.needtoday.com/storage/general/logo.png"
                  alt="Needtoday logo"
                  height="30"
                />
              </a>
            </div>
            <div className="col dropdownMenu">
              <DropdownMenu
                title="Channels"
                items={channels}
                isOpen={channelOpen}
                toggle={() => setChannelOpen(!channelOpen)}
                className="channel_dropdown"
              />
              <a
                href="https://www.needtoday.com/coming-soon/post-free-ad"
                className="nav_link"
              >
                Post Free Ad
              </a>
              <a
                href="https://needtoday.com/account/register-as-a-seller"
                className="nav_link"
              >
                Become a Seller
              </a>
              <UserProfile />
            </div>
          </div>
        </div>
      </div>

      {/* Second Top Header (Green Bar) */}
      <div className="second_top_header-bg second_top_header-bgAdmin">
        <div className="container-fluid">
          <div className="second_top_header row">
            <div className="col d-flex align-items-center">
              <DropdownMenu
                title="My Dashboard"
                items={myDashboardItems}
                isOpen={dashboardOpen}
                toggle={() => setDashboardOpen(!dashboardOpen)}
                className="dashboard_dropdown"
              />
              <DropdownMenu
                title="Seller Dashboard"
                items={sellerDashboardItems}
                isOpen={sellerDashboardOpen}
                toggle={() => setSellerDashboardOpen(!sellerDashboardOpen)}
                className="dashboard_dropdown"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
